import { render, staticRenderFns } from "./HotelFeeRuleCopy.vue?vue&type=template&id=99d625e6&scoped=true&"
import script from "./HotelFeeRuleCopy.vue?vue&type=script&lang=js&"
export * from "./HotelFeeRuleCopy.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "99d625e6",
  null
  
)

export default component.exports